import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import PictoCalculator from '../../../assets/calculator.inline.svg'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoCalculator,
  title: 'Pour ce faire, il est néanmoins nécessaire <strong>d’opter pour un régime réel d’imposition.</strong>'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 760px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const BottomText = styled(HomeSectionDescription)`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
`

const StyledButtonContainer = styled(ButtonContainer)`
  margin-top: 24px;
`

const RentalFiscal = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Les frais de gestion locative déductibles des impôts
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Dernier avantage de la gestion locative, et non des moindres, les frais payés à une agence spécialisée peuvent être déduits de vos impôts.      </HomeSectionSubtitle>
    <HomeSectionDescription>
      La gestion locative vous permet donc de défiscaliser, un atout majeur pour les propriétaires.
    </HomeSectionDescription>
    <PictoColContainer details={DETAILS_COL} />
    <HomeSectionDescription>
      Dans l’ensemble, les frais correspondant à la gestion locative d’un bien immobilier tournent autour de 5 et 10% des loyers perçus.
    </HomeSectionDescription>
    <BottomText>
      <strong>Avec Folhomee, bénéficiez de frais de gestion locative à seulement 3,9% de votre loyer.</strong><br />N’attendez plus et confiez la gestion locative de votre bien à nos experts locatifs partenaires !
    </BottomText>
    <StyledButtonContainer>
      <LaunchButton
        link='/nous-contacter'
        title='Contactez-nous' />
    </StyledButtonContainer>
  </Container>
)

export default RentalFiscal
