import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import PictoShop from '../../../assets/shop.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import PictoColContainer from '../../molecules/PictoColContainer'

const DETAILS_COL = [{
  icon: PictoShop,
  title: 'Une agence spécialisée est donc en mesure de fournir des conseils avisés sur la gestion locative du bien immobilier'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`
const LineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 32px 0;

  ${media.greaterThan('sm')`
    gap: 16px;
    margin: 40px 0;
  `}

  & > svg {
    height: 60px;

    ${media.greaterThan('sm')`
      height: 80px;
      width: 80px;
    `}
  }
`
const LineTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue')};
  width: 100%;

  ${media.greaterThan('sm')`
    font-size: 16px;
  `}
`

const RentalExperts = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h2'>
        L’avantage de bénéficier de conseils d’experts dans le domaine
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La gestion locative implique d’effectuer un certain nombre de tâches administratives, ce qui n’est pas forcément à la portée de tous.      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En effet, certaines notions peuvent être compliquées à appréhender, notamment pour des jeunes propriétaires dont le bien immobilier est le premier investissement.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        Par exemple, le montant du loyer ne se définit pas au hasard : en fonction de la zone géographique du bien, certains plafonds doivent être respectés. De la même manière, il est nécessaire de respecter les prix du marché immobilier. Un professionnel connaît ces informations et peut donc faire une estimation juste du loyer.
      </HomeSectionDescription>
    </Container>
  )
}

export default RentalExperts
