import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '500'
  }
}

const RentalExpertsDetails = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "rental-location.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      right={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle tag='h3'>
        Les propriétaires sont assurés de pouvoir louer rapidement leur bien immobilier
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Grâce à un loyer juste et compétitif, les propriétaires bénéficient d’un gain de temps considérable.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Les visites de l’appartement ou de la maison sont également assurées par le professionnel, un avantage appréciable : c’est un peu le béaba du métier d’agent immobilier. De toute évidence, cela peut faire toute la différence auprès des potentiels locataires.      </HomeSectionDescription>
    </ImageSection>
  )
}

export default RentalExpertsDetails
