import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoPurse from '../../../assets/purse.inline.svg'
import PictoMoney from '../../../assets/money.inline.svg'
import PictoExpert from '../../../assets/expert.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoPurse,
  title: 'Les loyers sont toujours versés'
}, {
  icon: PictoMoney,
  title: 'Les frais de gestion sont relativement bas',
  description: 'avec la possibilité de les déduire de vos impôts'
}, {
  icon: PictoExpert,
  title: 'Une gestion 100% experte de votre bien'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const RentalAdvantages = () => (
  <Container>
    <HomeSectionTitle tag='p'>
      Les bénéfices de la gestion locative par un professionnel
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La gestion locative implique de gérer de nombreuses missions relatives à la location de votre bien immobilier.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Dans la majorité des cas, vous avez vous-même une activité professionnelle qui vous prend du temps. De toute évidence, la gestion locative de votre bien ne peut pas empiéter sur votre temps de travail, ou sur votre temps libre. Voilà pourquoi accorder la gestion locative de votre bien immobilier neuf est un grand avantage&nbsp;:
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
  </Container>
)

export default RentalAdvantages
