import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 760px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const RentalWork = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h2'>
        Le professionnel prend en charge la gestion des éventuels travaux de réparation, rénovation et d’entretien
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Par ailleurs, le bien immobilier est toujours maintenu au goût du jour, pour que celui-ci reste attractif sur la durée et qu’il attire toujours autant de locataires.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Somme toute, votre bien est toujours entretenu et aux normes, ce qui vous évite de prendre des risques et d’avoir potentiellement des soucis avec des locataires.
      </HomeSectionDescription>
    </Container>
  )
}

export default RentalWork
