import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '500'
  },
  xxl: {
    height: '600'
  }
}

const RentalProfessional = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "rental-professional.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      reverse={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h3'>
        Gardez un bien immobilier toujours aux normes avec la gestion locative par un pro
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        En confiant votre gestion locative à un professionnel, vous vous assurez également que votre bien immobilier reste toujours aux normes.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Les agents immobiliers se tiennent toujours au fait des dernières dispositions légales pour la location des biens immobiliers. Le logement est donc toujours conforme aux dernières réglementations.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default RentalProfessional
