import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(BackgroundImage)`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  ${media.lessThan('lg')`
    display: none;
  `}
`

const StyledContainer = styled.div`
  ${media.greaterThan('lg')`
    display: none;
  `}
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0px 72px;
  `}

  ${media.greaterThan('lg')`
    max-width: 570px;
    margin-left: 50%;
  `}
`

const StyledImage = styled(GatsbyImage)`
 margin-top: 32px;
  ${media.greaterThan('sm')`
    margin-top: 40px;
  `}

  ${media.greaterThan('xl')`
    margin-top: 0;
  `}
`

const RentalIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "intro-rental.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <>
      <StyledImageSection
        alt='aide achat immobilier'
        Tag='div'
        {...bgImage}
        preserveStackingContext>
        <Content>
          <HomeSectionTitle tag='h2'>
            La gestion locative, qu’est-ce que c’est exactement&nbsp;?
          </HomeSectionTitle>
          <HomeSectionSubtitle>
            La gestion locative d’un bien immobilier se définit par l’ensemble des démarches et actions visant à mettre en location un appartement ou une maison. La gestion locative concerne donc aussi bien les biens immobiliers neufs, que les biens anciens.
          </HomeSectionSubtitle>
          <HomeSectionDescription>
            En revanche, investir dans <strong>un bien immobilier neuf pour faire de la gestion locative</strong> comporte de nombreux avantages, puisque, pour rappel, l’achat immobilier neuf permet de bénéficier de nombreux avantages fiscaux ainsi que de facilités de financement.
          </HomeSectionDescription>
        </Content>
      </StyledImageSection>
      <StyledContainer>
        <Content>
          <HomeSectionTitle tag='h2'>
            La gestion locative, qu’est-ce que c’est exactement&nbsp;?
          </HomeSectionTitle>
          <HomeSectionSubtitle>
            La gestion locative d’un bien immobilier se définit par l’ensemble des démarches et actions visant à mettre en location un appartement ou une maison. La gestion locative concerne donc aussi bien les biens immobiliers neufs, que les biens anciens.
          </HomeSectionSubtitle>
          <HomeSectionDescription>
            En revanche, investir dans <strong>un bien immobilier neuf pour faire de la gestion locative</strong> comporte de nombreux avantages, puisque, pour rappel, l’achat immobilier neuf permet de bénéficier de nombreux avantages fiscaux ainsi que de facilités de financement.
          </HomeSectionDescription>
        </Content>
        <StyledImage
          image={placeholderImage.childImageSharp.gatsbyImageData}
          alt='Folhomee Rental Intro'
          fadeIn={false} 
          loading='eager' />
      </StyledContainer>
    </>
  )
}

export default RentalIntro
