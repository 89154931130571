import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import PictoPiggyLarge from '../../../assets/piggy-large.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoPiggyLarge,
  title: 'La plupart du temps, ce type d’assurance vous permet de vous couvrir à hauteur de 70&nbsp;000&nbsp;€.'
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 700px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const OtherStyledList = styled(StyledList)`
  margin-top: 0;
`

const RentalGarantee = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h2'>
        La garantie de percevoir ses loyers, même en cas d’impayés
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Faire appel à un professionnel pour la gestion locative de votre bien vous permet également de vous assurer de percevoir vos loyers.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En cas d’impayés, une garantie de risques locatifs peut vous être proposée afin de vous protéger des loyers non payés.
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
      <OtherStyledList>
        <li>
          le loyer est <strong>inférieur à 50%</strong> des revenus du/des locataire(s)&nbsp;;
        </li>
        <li>
          le loyer ne dépasse pas les <strong>2000&nbsp;€</strong>&nbsp;;
        </li>
        <li>
          le bien est considéré comme la <strong>résidence principale</strong> des locataires.
        </li>
      </OtherStyledList>
      <HomeSectionDescription>
        Ce contrat d’assurance vous permet également, en tant que propriétaire, de vous assurer en cas de détérioration du bien.
      </HomeSectionDescription>
    </Container>
  )
}

export default RentalGarantee
