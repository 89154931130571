import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 840px;
  margin: auto;
  height: 600px;

  ${media.greaterThan('xl')`
    max-width: 550px;
    margin-right: 50%;
  `}
`

const RentalHero = () => (
  <StyledContainer>
    <Header max={100}>
      Gestion locative : l’intérêt de confier la gestion de votre bien
    </Header>
    <Subtitle>
      La gestion locative d’un bien immobilier demande parfois beaucoup de temps et d’énergie. Pour pallier les éventuels soucis liés à la gestion locative de votre bien immobilier neuf, des solutions existent.
    </Subtitle>
    <Description>
      Vous pouvez confier la gestion de votre bien immobilier à un professionnel pour vous permettre non seulement de gagner du temps, mais aussi d’avoir la garantie de recevoir vos loyers, même en cas d’impayés. Zoom sur les avantages de confier la gestion locative de votre bien immobilier neuf.
    </Description>
  </StyledContainer>
)

export default RentalHero
