import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import RentalWork from '../components/organisms/RentalWork'
import RentalIntro from '../components/organisms/RentalIntro'
import SiteSection from '../components/atoms/SiteSection/'
import RentalFiscal from '../components/organisms/RentalFiscal'
import RentalDetails from '../components/organisms/RentalDetails'
import RentalExperts from '../components/organisms/RentalExperts'
import RentalGarantee from '../components/organisms/RentalGarantee'
import RentalAdvantages from '../components/organisms/RentalAdvantages'
import RentalTopSection from '../components/organisms/RentalTopSection'
import RentalProfessional from '../components/organisms/RentalProfessional'
import RentalExpertsDetails from '../components/organisms/RentalExpertsDetails'

const TITLE = 'Gestion locative : l\'intérêt de confier la gestion de votre bien'
const DESCRIPTION = 'Confier la gestion locative de votre bien immobilier à un expert comporte de nombreux avantages.'

const GestionLocative = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <RentalTopSection />
    <SiteSection id='rental-intro' $first={true}>
      <RentalIntro />
    </SiteSection>
    <SiteSection id='rental-details'>
      <RentalDetails />
    </SiteSection>
    <SiteSection id='rental-advantages'>
      <RentalAdvantages />
    </SiteSection>
    <SiteSection id='rental-experts'>
      <RentalExperts />
    </SiteSection>
    <SiteSection id='rental-experts-details'>
      <RentalExpertsDetails />
    </SiteSection>
    <SiteSection id='rental-garantee'>
      <RentalGarantee />
    </SiteSection>
    <SiteSection id='rental-professional'>
      <RentalProfessional />
    </SiteSection>
    <SiteSection id='rental-work'>
      <RentalWork />
    </SiteSection>
    <SiteSection id='rental-fiscal'>
      <RentalFiscal />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

GestionLocative.propTypes = {
  location: PropTypes.object.isRequired
}

export default GestionLocative
