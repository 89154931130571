import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import LaunchButton from '../../atoms/LaunchButton'
import PictoEasy from '../../../assets/easy.inline.svg'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoEasy,
  title: 'Globalement, la gestion locative implique d’effectuer énormément de tâches liées à votre bien immobilier, dont certaines ne sont pas si évidentes, surtout pour un nouveau propriétaire.',
}]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 710px;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const RentalDetails = () => {
  return (
    <Container>
      <HomeSectionTitle tag='h2'>
        Concrètement, voici ce qu’implique la gestion locative d’un bien immobilier&nbsp;:
      </HomeSectionTitle>
      <StyledList>
        <li>
          <strong>Recherche</strong> de locataires
        </li>
        <li>
          <strong>Création des contrats</strong> de location (baux)
        </li>
        <li>
          Faire les <strong>états des lieux</strong> (entrée et sortie)
        </li>
        <li>
          <strong>Déterminer un loyer</strong> en prenant compte les plafonds de loyer (loi Pinel, par exemple)
        </li>
        <li>
          Recouvrement des <strong>loyers impayés</strong>
        </li>
        <li>
          Paiement des <strong>charges de copropriété</strong>
        </li>
        <li>
          <strong>Assister aux assemblées générales</strong> de copropriétaires
        </li>
        <li>
          <strong>Gestion administrative</strong> (édition des quittances de loyer, déclaration des revenus fonciers, etc).
        </li>
      </StyledList>
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        Voilà pourquoi se faire accompagner par un gestionnaire vous permet de profiter sereinement de tous les bénéfices d’un bien immobilier en location, sans avoir à gérer les éléments parfois désagréables et chronophages.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/services/gestion-locative'
          title='Confiez la gestion locative de votre bien à Folhomee'
          background='blue' />
      </ButtonContainer>
    </Container>
  )
}

export default RentalDetails
