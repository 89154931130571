import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import RentalHero from '../../molecules/RentalHero'
import TopSection from '../../molecules/TopSection'
import RentalHouseImage from '../../molecules/RentalHouseImage'

const StyledRentalHouseImage = styled(RentalHouseImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: auto;
    top: 295px;
  `}
`

const RentalTopSection = () => (
  <TopSection component={StyledRentalHouseImage}>
    <RentalHero />
  </TopSection>
)

export default RentalTopSection
